import React, { useState, useRef } from "react";
import { navigate } from "gatsby";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { Ring } from "react-awesome-spinners";
import { useForm } from "react-hook-form";

import TextError from "../../components/notifications/TextError";
import Success from "../../components/notifications/SuccessMessage";
import Error from "../../components/notifications/Error";
import Layout4 from "../../components/layout/layout4";
import Layout from "../../components/layout/layout";
import {
  CalendarIcon,
  TrashIcon,
  ChevronDownIcon,
  CheckCircleIcon,
} from "@heroicons/react/outline";

import { VideoUpload } from "../../components/VideoUpload";

import { nationalities } from "../../constants/nationalities";
import { countries } from "../../constants/countries";

import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebase";

import SEO from "../../components/seo";

// import { CheckCircleIcon } from '@heroicons/react/20/solid'

const benefits = [
  "Champion Social Causes: Use this platform to advocate for issues close to your heart.",
  "Professional and Personal Growth: Enhance your leadership, public speaking, and community service skills.",
  "Global Representation: Carry the pride of Singapore on an international stage.",
];

const MspiForm2 = () => {
  const [close, setClose] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [result, setResult] = useState("");
  const [declare, setDeclare] = useState(false);
  const [agree, setAgree] = useState(false);
  const [birthDay, setBirthDay] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [birthdateError, setBirthDateError] = useState("");
  const [videoError, setVideoError] = useState("");
  const [videoFile, setVideoFile] = useState();
  const [progress, setProgress] = useState(0);

  const {
    register,
    setValue,
    control,
    handleSubmit,
    watch,

    formState: { errors, isDirty, isSubmitted, isSubmitting },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      honey: "",
      name: "",
      preferredname: "",
      email: "",
      mobile: "",
      nationality: "",
      citizenship: "",
      birthday: "",
      occupation: "",
      education: "",
      language: "",
      bust: "",
      waist: "",
      hip: "",
      height: "",
      weight: "",
      fb: "",
      ig: "",
      tt: "",
      yt: "",
      lk: "",
      other: "",
      about: "",
      mspiVideo: "",
      videoLink: "",
    },
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
  });

  // if (videoFile) {
  //   console.log("video", videoFile);
  // }

  // if (errors) {
  //   console.log("errors: ", errors);
  //   console.log("touchedFields: ", touchedFields);
  // }
  // if (touchedFields) {
  //   console.log("touchedFields: ", touchedFields);
  // }

  // if (isDirty) {
  //   console.log("isDirty", isDirty);
  // }
  // if (isValid) {
  //   console.log("isValid", isValid);
  // }

  let count;

  if (watch("message")) {
    count = watch("message").length;
  } else {
    count = 0;
  }

  // if (birthdateError) {
  //   console.log("birthdayerror", birthdateError);
  // }

  const birthDaytitleRef = useRef();

  const scrollToBirthDay = () => {
    birthDaytitleRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleCalendarClose = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const birthDate = new Date(birthDay);
    const birthYear = birthDate.getFullYear();
    const age = currentYear - birthYear;
    if (age < 18 || age > 28) {
      setBirthDateError("Must be between 18 - 28 as of 2023");
    } else if (!birthDay) {
      setBirthDateError("Please select your date of birth");
    } else {
      setBirthDateError("");
    }
    console.log("date selected", birthDay);
    console.log("age", age);

    // const ageDifMs = new Date(2023, 1, 1) - new Date(birthDay).getTime();
    // const ageDate = new Date(ageDifMs);
    // const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    // // console.log("age: ", age);
    // if (age < 18 || age > 28) {
    //   setBirthDateError("Must be between 18 - 28 as of 11 June 2022");
    // } else if (!age || birthDay === "") {
    //   setBirthDateError("empty");
    // } else {
    //   setBirthDateError("updated");
    // }
  };

  const uploadVideo = async (e, preset) => {
    const file = e.target.files[0];
    setVideoFile(e.target.files[0]);
    let size = parseFloat(file.size / 1024 / 1024);
    // console.log("size:", size);
    if (size > 250) {
      setUploadError("File size larger than 250MB");
      return;
    }
    firebaseUpload(file, preset);
  };

  const firebaseUpload = (file, preset) => {
    setVideoLoading(true);

    if (!file) return;
    const sotrageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(sotrageRef, file);

    uploadTask.on(
      "state_changed",
      snapshot => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      error => {
        // console.log(error);
        setUploadError("system error encountered");
        setVideoLoading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          // console.log("File available at", downloadURL);
          setValue(`${preset}`, downloadURL);
          setVideoLoading(false);
          setVideoError("");
        });
      }
    );
  };

  // ------Cloudinary
  // const uploadVideo = async (e, preset) => {
  //   console.log("uploadVideo triggered");
  //   console.log("preset: ", preset);
  //   setVideoLoading(true);

  //   const formData = new FormData();
  //   formData.append("file", e.target.files[0]);
  //   formData.append("upload_preset", preset);
  //   console.log("formData:", formData);
  //   axios
  //     .post(
  //       // `${process.env.GATSBY_CLOUDINARY_DIRECT_UPLOAD}/upload`,
  //       `${process.env.REACT_APP_CLOUDINARY_DIRECT_UPLOAD}/upload`,
  //       formData
  //     )
  //     .then(response => {
  //       console.log("Success:", response.data);
  //       // setSinglePhotos(prev => [...prev, data.secure_url]);
  //       console.log("secure_url", response.data.secure_url);
  //       setValue(`${preset}`, response.data.secure_url);
  //       setVideoLoading(false);
  //       setVideoError("");
  //     })
  //     .catch(err => {
  //       console.error("Error:", err);
  //       setUploadError("system error encountered");
  //       setVideoLoading(false);
  //     });
  // };

  const deleteVideo = type => {
    // console.log("delete video");
    setUploadError("");
    setValue(`mspiVideo`, "");
  };

  const keyDeleteVideo = e => {
    if ((e.keyCode = 8)) {
      setUploadError("");
      setValue(`mspiVideo`, "");
    }
  };

  const updateVideoLink = () => {
    setVideoError("");
  };

  const agreeHandler = e => {
    // console.log("agree:", e.target.checked);
    setAgree(e.target.checked);
  };
  const declareHandler = e => {
    // console.log("agree:", e.target.checked);
    setDeclare(e.target.checked);
  };

  const onSubmit = async data => {
    // console.log("onSubmit: ", data);
    if (data.honey !== "") {
      setErrorText("Spam suspected");
      return;
    }
    if (birthDay === "") {
      setBirthDateError("empty");
      // console.log("birthday error triggered");
      scrollToBirthDay();
      return;
    }
    if (birthDay && birthDay !== "") {
      const formattedDate = moment(birthDay).format("LL");
      data.birthday = formattedDate;
    }

    if (data.videoLink === "" && data.mspiVideo === "") {
      console.log("video missing");
      // setVideoError("Please upload or share your introductory video link");
      // return;
    }

    delete data.honey;
    // console.log("submitted data: ", data);

    let airtableURL;
    if (data) {
      airtableURL = `${process.env.GATSBY_API_STATIC}/addmspi2`;
      // airtableURL = `${process.env.REACT_APP_API_STATIC}/addmspi2`;
    }
    try {
      setErrorText("");
      setLoading(true);

      const res = await axios.post(airtableURL, data);
      // console.log("status: ", res);
      if (res.data.statusCode === 400) {
        console.log("res.data", res.data);
        setErrorText(res.data.message);
        setLoading(false);
      } else if (res.data.statusCode === 200) {
        // setResult("Submitted successfully");
        setLoading(false);
        navigate("/forms/successpage", {
          onComplete: () => {
            console.log("Navigation complete!");
          },
        });
      }
    } catch (err) {
      // console.log("catch error", err);
      setErrorText(
        err.message +
          "We have encountered a technical issue and your form was not submitted. Please email us directly at contactus@singaporewomenassociation.org"
      );
      setLoading(false);
    }
  };

  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout4>
        <SEO
          title="MSPI Application Form"
          description="Miss Singapore International will represent Singapore on an International platform with 84 contestants around the world. The exchanges and exposure aim to bring forth more women to anchor a role in the society with a charitable approach, grace, integrity and honour."
          image="https://res.cloudinary.com/dkm14ge3g/image/upload/v1638588570/Logo/qiuk4zzjcgmidemdqavw.webp"
          imageAlt="Miss Singapore International Pageant | Annual Charity Gala Dinner"
        />
        <div className="relative">
          <div className="absolute inset-0 ">
            <img
              className="object-cover object-top w-full h-full grayscale"
              src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637152184/formPages/rgj2asybbv8wwcugcvhj.jpg"
              alt=""
            />
            <div
              className="absolute inset-0 bg-opacity-50 bg-swa-2 mix-blend-multiply"
              aria-hidden="true"
            />
          </div>
          <div className="relative px-4 mx-auto py-14 max-w-7xl sm:py-24 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
              Miss Singapore Pageant International application form
            </h1>

            <p className="max-w-3xl mt-6 text-xl text-indigo-100">
              Representing Singapore in Miss International Pageant. <br />
              {/* <span className="text-2xl font-bold text-swa-4">
                Beauty with Heart and Purpose
              </span> */}
            </p>
          </div>
        </div>
        {errorText === "" && (
          <>
            <div className="bg-white">
           
              <div className="py-8 mx-auto max-w-7xl sm:px-6 sm:py-8 lg:px-8">
                <div className="relative px-6 overflow-hidden text-left shadow-2xl isolate bg-swa-1 py-14 sm:text-center sm:rounded-3xl sm:px-16">
                  <h2 className="max-w-4xl mx-auto text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  Form is current under maintenance. <br />Please try again tomorrow 23 Feb 2024
                  </h2>
                  <br />
                  {/* <h3 className="max-w-3xl mx-auto text-2xl font-bold tracking-tight text-white sm:text-3xl">
                    Your Journey Starts at Miss Singapore Pageant International
                    2024
                  </h3>
                  <p className="max-w-4xl mx-auto mt-6 text-lg leading-8 text-gray-100">
                    Join Miss Singapore Pageant International 2024 and take a
                    step towards making a difference. This platform is more than
                    a beauty contest; it's an opportunity to champion social
                    causes, develop leadership skills, and represent Singapore
                    globally. We're looking for individuals ready to impact the
                    community positively and grow personally and professionally.
                    If you're passionate about charity, aspire to inspire, and
                    want to develop your potential, apply now. Let's make your
                    voice heard and your actions count.
                  </p> */}

                  {/* <div className="flex items-center justify-center mt-10 gap-x-6">
            <a
              href="#"
              className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Get started
            </a>
            <a href="#" className="text-sm font-semibold leading-6 text-white">
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div> */}
                  {/* <svg
                    viewBox="0 0 1024 1024"
                    className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                    aria-hidden="true"
                  >
                    <circle
                      cx={512}
                      cy={512}
                      r={512}
                      fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                      fillOpacity="0.7"
                    />
                    <defs>
                      <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                        <stop stopColor="#7775D6" />
                        <stop offset={1} stopColor="#E935C1" />
                      </radialGradient>
                    </defs>
                  </svg> */}
                  {/* <ul className="max-w-3xl mx-auto mt-6 text-lg leading-8 text-left text-gray-100">
                    <li>
                      Champion Social Causes: Use this platform to advocate for
                      issues close to your heart.
                    </li>
                    <li>
                      Professional and Personal Growth: Enhance your leadership,
                      public speaking, and community service skills.
                    </li>
                    <li>
                      Global Representation: Carry the pride of Singapore on an
                      international stage.
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>

            {/* <div className="p-5 mx-3 my-8 bg-gray-200 rounded-lg md:mx-auto md:max-w-xl lg:max-w-2xl">
      

              <h3 className="text-lg font-bold leading-6 text-swa-1">
                Thank you for your interest. <br />
                Please read and adhere to the following official rules and
                regulations of Miss Singapore Pageant International. To be
                eligible as a Contestant:
              </h3>
              <ul>
                <li className="max-w-2xl mt-1 text-gray-500">
                  Must be single, never married, nor given birth to a child and
                  a born female.
                </li>

                <li className="max-w-2xl mt-1 text-gray-500">
                  Singapore Citizen
                </li>
                <li className="max-w-2xl mt-1 text-gray-500">
                  Height 160 centimetres and above.
                </li>

                <li className="max-w-2xl mt-1 text-gray-500">
                  Must be at least 18 years old.
                </li>

                <li className="max-w-2xl mt-1 text-gray-500">
                  Shortlisted applicants will be notified for an audition and
                  will be requested to provide proof of citizenship (e.g. NRIC)
                  for verification purpose. We regret that we may not be able to
                  respond to all applications individually.
                </li>
                <li className="max-w-2xl mt-1 text-gray-500">
                  Selected applicants must be committed to attending the three
                  (3) months of training and preparation sessions starting from
                  May 2024, leading up to Miss Singapore Pageant International
                  final.
                </li>
                <li className="max-w-2xl mt-1 text-gray-500">
                  Be of good health, sound mind and moral character.
                </li>

                <li className="max-w-2xl mt-1 text-gray-500">
                  <span className="font-bold">Filling out the form:</span> You
                  may complete the Personal Information section and leave out
                  the rest to express your interest in participating. We will
                  contact you later to provide the rest of the information in
                  Body Measurement, profile photos and introduction video.
                </li>
              </ul>
            </div>
            <div className="mx-auto mt-2 max-w-7xl lg:px-8">
              <div className="max-w-2xl mx-auto text-start">
                <p className="mt-6 text-lg text-gray-600">
                  This pageant is helmed by Singapore Women Association. An
                  association established since 1975 to promote the well being
                  of young women and build soft skills that will shine in you as
                  an individual.
                </p>
              </div>
            </div> */}
          </>
        )}
      
        {/* {errorText && (
          <div className="mx-3 my-8 rounded-lg md:mx-auto md:max-w-xl lg:max-w-2xl">
            <Error msg={errorText} />
          </div>
        )}
        {isSubmitted && result && (
          <div className="mx-3 my-8 rounded-lg md:mx-auto md:max-w-xl lg:max-w-2xl">
            <Success msg={result}>
              You will be notified if you are shortlisted.
            </Success>
          </div>
        )}
         */}
        {/* <div>
          {(!isSubmitted &&
            birthdateError === "empty" &&
            birthdateError === "updated") ||
            (!result && (
              <form
                className="mx-3 mb-12 space-y-8 divide-y divide-gray-200 md:mx-auto md:max-w-xl lg:max-w-2xl"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                      <h3 className="text-lg font-bold leading-6 text-swa-1">
                        Personal Information
                      </h3>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="first-name"
                          className={
                            errors.name
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Family Name, Given Name
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("name", {
                              required: true,
                              maxLength: 100,
                            })}
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                          />
                          <span className="mt-2 text-sm text-gray-500">
                            (as shown in NRIC)
                          </span>
                          {errors.name?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              A name is required
                            </p>
                          )}
                          {errors.name?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="preferredname"
                          className={
                            errors.preferredname
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Any preferred name
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("preferredname", {
                              required: false,
                              maxLength: 100,
                            })}
                            type="text"
                            name="preferredname"
                            id="preferredname"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                          />

                          {errors.preferredname?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="nationality"
                          className={
                            errors.nationality
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Nationality
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="flex content-center justify-start w-full">
                            <select
                              {...register("nationality", {
                                required: true,
                              })}
                              type="text"
                              name="nationality"
                              id="nationality"
                              className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3"
                            >
                              {nationalities &&
                                nationalities.map((nationality, i) => {
                                  return (
                                    <option key={i} value={nationality}>
                                      {nationality}
                                    </option>
                                  );
                                })}
                            </select>
                            <ChevronDownIcon className="z-20 w-6 h-6 mt-2 text-gray-400 -ml-9" />
                          </div>
                          {errors.nationality?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              Nationality is required
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="citizenship"
                          className={
                            errors.citizenship
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Citizenship
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="flex content-center justify-start w-full">
                            <select
                              {...register("citizenship", {
                                required: true,
                              })}
                              id="citizenship"
                              name="citizenship"
                              type="text"
                              autoComplete="off"
                              className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 "
                            >
                              {countries &&
                                countries.map((country, i) => {
                                  return (
                                    <option key={i} value={country}>
                                      {country}
                                    </option>
                                  );
                                })}
                            </select>
                            <ChevronDownIcon className="z-20 w-6 h-6 mt-2 text-gray-400 -ml-9" />
                          </div>
                          {errors.citizenship?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              A citizenship is required
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          ref={birthDaytitleRef}
                          htmlFor="birthday"
                          className={
                            errors.birthday
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Date of Birth
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-1">
                          <div className="flex content-center justify-start w-full">
                            <DatePicker
                              selected={birthDay}
                              onChange={date => {
                                setValue(`birthday`, date);
                                setBirthDay(date);
                              }}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dateFormat="dd MMMM yyyy"
                              dateFormatCalendar="MMMM"
                              dropdownMode="select"
                              className="input-text"
                              calendarClassName=""
                             
                            />
                            <CalendarIcon className="z-20 w-6 h-6 mt-2 text-gray-400 -ml-9" />
                          </div>
                          {birthdateError !== "" &&
                            birthdateError !== "empty" &&
                            birthdateError !== "updated" && (
                              <p className="text-sm text-red-500 ">
                                {birthdateError}
                              </p>
                            )}
                          {birthdateError === "empty" && (
                            <p className="text-sm text-red-500 ">
                              Please indicate your date of birth
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="occupation"
                          className={
                            errors.occupation
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Occupation
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("occupation", {
                              required: true,
                              maxLength: 100,
                            })}
                            id="occupation"
                            name="occupation"
                            type="text"
                            autoComplete="off"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                          />
                          {errors.occupation?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              Occupation is required
                            </p>
                          )}
                          {errors.occupation?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="education"
                          className={
                            errors.education
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Highest education
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="flex content-center justify-start w-full">
                            <select
                              {...register("education", {
                                required: true,
                                maxLength: 100,
                              })}
                              id="education"
                              name="education"
                              type="text"
                              autoComplete="off"
                              className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm appearance-none focus:ring-swa-1 focus:border-swa-3 sm:"
                            >
                              <option value="Primary">Primary</option>
                              <option value="GCE O Level">GCE 'O' Level</option>
                              <option value="GCE A Level">GCE 'A' Level</option>
                              <option value="N Level">N Level</option>
                              <option value="Vocational">Vocational</option>
                              <option value="Bachelor">Bachelor</option>
                              <option value="Master">Master</option>
                              <option value="PhD">PhD</option>
                            </select>
                            <ChevronDownIcon className="z-20 w-6 h-6 mt-2 text-gray-400 -ml-9" />
                          </div>
                          {errors.education?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              Education is required
                            </p>
                          )}
                          {errors.education?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="language"
                          className={
                            errors.language
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Spoken language
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("language", {
                              required: true,
                              maxLength: 100,
                            })}
                            id="language"
                            name="language"
                            type="text"
                            autoComplete="off"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                          />
                          {errors.language?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              Language is required
                            </p>
                          )}
                          {errors.language?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="mobile"
                          className={
                            errors.mobile
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Mobile number
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="flex mt-1 rounded-md shadow-sm">
                            <span className="inline-flex items-center px-3 text-gray-500 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 sm:text-sm">
                              +65
                            </span>
                            <input
                              {...register("mobile", {
                                required: true,
                                maxLength: 20,
                              })}
                              id="mobile"
                              name="mobile"
                              type="number"
                              autoComplete="off"
                              className="block w-full max-w-lg border-gray-300 shadow-sm rounded-r-md focus:ring-swa-1 focus:border-swa-3 sm:"
                            />
                          </div>
                          {errors.mobile?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              A contact number is required
                            </p>
                          )}
                          {errors.mobile?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="email"
                          className={
                            errors.email
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Email address
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("email", {
                              required: true,
                              maxLength: 100,
                              pattern:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="off"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-3 focus:border-swa-3 sm:"
                          />
                          {errors.email?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              Email address is required
                            </p>
                          )}
                          {errors.email?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                          {errors.email?.type === "pattern" && (
                            <span className="text-sm text-red-500">
                              Please enter a valid email format
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                      <h3 className="text-lg font-bold leading-6 text-swa-1">
                        Body measurement
                      </h3>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="bust"
                          className={
                            errors.bust
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Bust
                        </label>
                        <div className="relative mt-1 sm:mt-0 sm:col-span-1">
                          <input
                            {...register("bust", {
                              required: false,
                              maxLength: 100,
                            })}
                            type="number"
                            name="bust"
                            id="bust"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency"
                            >
                              inches
                            </span>
                          </div>
                          {errors.bust?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              A bust measurement is required
                            </p>
                          )}
                          {errors.bust?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="waist"
                          className={
                            errors.waist
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Waist
                        </label>
                        <div className="relative mt-1 sm:mt-0 sm:col-span-1">
                          <input
                            {...register("waist", {
                              required: false,
                              maxLength: 20,
                            })}
                            id="waist"
                            name="waist"
                            type="number"
                            autoComplete="off"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency"
                            >
                              inches
                            </span>
                          </div>
                          {errors.waist?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              A waist measurement is required
                            </p>
                          )}
                          {errors.waist?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="hip"
                          className={
                            errors.hip
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Hip
                        </label>
                        <div className="relative mt-1 sm:mt-0 sm:col-span-1">
                          <input
                            {...register("hip", {
                              required: false,
                              maxLength: 100,
                            })}
                            id="hip"
                            name="hip"
                            type="number"
                            autoComplete="off"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-3 focus:border-swa-3 sm:"
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency"
                            >
                              inches
                            </span>
                          </div>
                          {errors.hip?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              A hip measurement is required
                            </p>
                          )}
                          {errors.hip?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="height"
                          className={
                            errors.height
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Height
                        </label>
                        <div className="relative mt-1 sm:mt-0 sm:col-span-1">
                          <input
                            {...register("height", {
                              required: false,
                              maxLength: 100,
                            })}
                            id="height"
                            name="height"
                            type="number"
                            autoComplete="off"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-3 focus:border-swa-3 sm:"
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency"
                            >
                              cm
                            </span>
                          </div>
                          {errors.height?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              A height measurement is required
                            </p>
                          )}
                          {errors.height?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="weight"
                          className={
                            errors.weight
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Weight
                        </label>
                        <div className="relative mt-1 sm:mt-0 sm:col-span-1">
                          <input
                            {...register("weight", {
                              required: false,
                              maxLength: 100,
                            })}
                            id="weight"
                            name="weight"
                            type="number"
                            autoComplete="off"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-3 focus:border-swa-3 sm:"
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency"
                            >
                              kg
                            </span>
                          </div>
                          {errors.weight?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              A weight measurement is required
                            </p>
                          )}
                          {errors.weight?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-8 space-y-6 divide-y divide-gray-200 sm:pt-10 sm:space-y-5">
                    <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                      <h3 className="text-lg font-bold leading-6 text-swa-1">
                        Profile photo and introduction video
                      </h3>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="other"
                          className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Your profile photo
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              {...register("other", {
                                required: false,
                                maxLength: 200,
                              })}
                              placeholder=""
                              type="text"
                              name="other"
                              id="other"
                              autoComplete="off"
                              className="flex-1 block w-full min-w-0 border-gray-300 rounded-none focus:ring-swa-1 focus:border-swa-3 rounded-r-md sm:"
                            />
                          </div>
                          <p className="mt-2 text-sm text-gray-500">
                            You may share more than one photo (e.g. headshot and
                            full figure). Provide the shared link of your
                            Dropbox, Google Drive folder or any photo sharing
                            site.
                          </p>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="about"
                          className={
                            errors.about
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          About yourself
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <textarea
                            {...register("about", {
                              maxLength: 2000,
                            })}
                            id="about"
                            name="about"
                            rows={3}
                            className="block w-full max-w-lg border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:"
                            defaultValue={""}
                            placeholder="max 2000 characters"
                          />
                          {errors.about?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              A message is required
                            </p>
                          )}
                          {errors.about?.type === "maxLength" && (
                            <p className="text-sm text-red-500 ">
                              1000 max characters allowed
                            </p>
                          )}

                          <p className="mt-2 text-sm text-gray-500">
                            Write a few sentences about yourself, your interests
                            or hobbies.
                          </p>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="mspiVideo"
                          className={
                            videoError
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Please upload a short introduction video clip
                          <br />
                          (max 1 min)
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <div className="mt-1 sm:mt-0 sm:col-span-3">
                              <input
                                {...register("videoLink", {
                                  required: false,
                                  maxLength: 100,
                                })}
                                type="text"
                                name="videoLink"
                                id="videoLink"
                                placeholder="link to introduction video"
                                onChange={updateVideoLink}
                                className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                              />
                              <p className="mt-2 mb-5 text-sm text-gray-500 ">
                                Upload your introductory video to Youtube,
                                Dropbox, Google Drive or any video sharing site
                                and share your link here.
                              </p>
                              {errors.videoLink?.type === "maxLength" && (
                                <span className="text-sm text-red-500">
                                  You have exceeded maximum length of 100
                                  characters
                                </span>
                              )}
                            </div>
                          </div>
                          <label
                            htmlFor="mspiLink"
                            className={
                              videoError
                                ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                                : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                            }
                          >
                        
                          </label>
                          {videoError !== "" && (
                            <p className="text-sm text-red-500 ">
                              {videoError}
                            </p>
                          )}
                          
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-8 space-y-6 divide-y divide-gray-200 sm:pt-10 sm:space-y-5">
                    <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                      <h3 className="text-lg font-bold leading-6 text-swa-1">
                        Social Media references (optional)
                      </h3>
                      <p className="max-w-2xl mt-1 text-gray-500">
                        Please provide one or more of your social media
                        references where appropriate. This is only for our
                        initial assessment.
                      </p>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="fb"
                          className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Facebook
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              {...register("fb", {
                                required: false,
                                maxLength: 100,
                              })}
                              type="text"
                              name="fb"
                              id="fb"
                              autoComplete="off"
                              placeholder="paste full link to your facebook page"
                              className="flex-1 block w-full min-w-0 border-gray-300 rounded-none focus:ring-swa-1 focus:border-swa-3 rounded-r-md sm:"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="ig"
                          className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Instagram
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              {...register("ig", {
                                required: false,
                                maxLength: 100,
                              })}
                              placeholder="provide your IG handle or full link"
                              type="text"
                              name="ig"
                              id="ig"
                              autoComplete="off"
                              className="flex-1 block w-full min-w-0 border-gray-300 rounded-none focus:ring-swa-1 focus:border-swa-3 rounded-r-md sm:"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="tt"
                          className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          TikTok
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              {...register("tt", {
                                required: false,
                                maxLength: 100,
                              })}
                              placeholder="provide your TikTok handle or full link"
                              type="text"
                              name="tt"
                              id="tt"
                              autoComplete="off"
                              className="flex-1 block w-full min-w-0 border-gray-300 rounded-none focus:ring-swa-1 focus:border-swa-3 rounded-r-md sm:"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="yt"
                          className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Youtube
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              {...register("yt", {
                                required: false,
                                maxLength: 100,
                              })}
                              type="text"
                              name="yt"
                              id="yt"
                              autoComplete="off"
                              placeholder="paste full link to your Youtube channel"
                              className="flex-1 block w-full min-w-0 border-gray-300 rounded-none focus:ring-swa-1 focus:border-swa-3 rounded-r-md sm:"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="lk"
                          className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          LinkedIn
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              {...register("lk", {
                                required: false,
                                maxLength: 100,
                              })}
                              type="text"
                              name="lk"
                              id="lk"
                              autoComplete="off"
                              placeholder="paste full link to your LinkedIn page"
                              className="flex-1 block w-full min-w-0 border-gray-300 rounded-none focus:ring-swa-1 focus:border-swa-3 rounded-r-md sm:"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-8 space-y-6 divide-y divide-gray-200 sm:pt-10 sm:space-y-5">
                    <div>
                      <h3 className="text-lg font-bold leading-6 text-swa-1">
                        Agreement &amp; Declarations
                      </h3>
                    </div>
                    <div className="space-y-6 divide-y divide-gray-200 sm:space-y-5">
                      <div className="pt-6 sm:pt-5">
                        <div role="group" aria-labelledby="label-email">
                          <div className="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-baseline">
                            <div className="mt-4 sm:mt-0 sm:col-span-2">
                              <div className="max-w-lg space-y-4">
                                <div className="relative flex items-start">
                                  <div className="flex items-center h-5">
                                    <input
                                      id="declaration"
                                      name="declaration"
                                      type="checkbox"
                                      className="w-4 h-4 border-gray-300 rounded text-swa-1 focus:ring-swa-3"
                                      onChange={e => declareHandler(e)}
                                    />
                                  </div>
                                  <div className="ml-3 ">
                                    <label
                                      htmlFor="declarations"
                                      className="font-medium text-gray-700"
                                    >
                                      Declarations
                                    </label>
                                    <p className="text-gray-500">
                                      I declare that all information provided in
                                      this form application is true and valid.
                                      Any misrepresentation of the truth will
                                      result in immediate disqualification from
                                      the pageant.
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  <div className="relative flex items-start">
                                    <div className="flex items-center h-5">
                                      <input
                                        id="offers"
                                        name="offers"
                                        type="checkbox"
                                        className="w-4 h-4 border-gray-300 rounded text-swa-1 focus:ring-swa-3"
                                        onChange={e => agreeHandler(e)}
                                      />
                                    </div>
                                    <div className="ml-3 ">
                                      <label
                                        htmlFor="offers"
                                        className="font-medium text-gray-700"
                                      >
                                        Agreement
                                      </label>
                                      <p className="text-gray-500">
                                        I agree to all the rules and regulations
                                        stated on the application, including
                                        providing the necessary documentation
                                        for verification purposes.
                                      </p>
                                      <p className="mt-3 text-gray-500">
                                        I consent for Singapore Women’s
                                        Association collecting, using,
                                        disclosing to third parties and
                                        transferring overseas to international
                                        pageant bodies, my personal information
                                        for processing consistent with the
                                        purposes of the Singapore and
                                        International pageants.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    {!isSubmitting && (
                      <button
                        type="submit"
                        disabled={
                          !isDirty ||
                          errors.name ||
                          errors.email ||
                          errors.mobile ||
                          errors.nationality ||
                          errors.citizenship ||
                          errors.birthday ||
                          errors.occupation ||
                          errors.education ||
                          errors.language ||
                          errors.bust ||
                          errors.waist ||
                          errors.hip ||
                          errors.height ||
                          errors.weight ||
                          errors.fb ||
                          errors.ig ||
                          errors.tt ||
                          errors.yt ||
                          errors.lk ||
                          errors.other ||
                          errors.about ||
                          !agree ||
                          !declare
                        }
                        className="inline-flex justify-center px-4 py-2 ml-3 font-medium text-white border border-transparent rounded-md shadow-sm bg-swa-1 hover:bg-swa-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-swa-3 disabled:opacity-30"
                      >
                        Submit
                      </button>
                    )}
                    {loading && (
                      <div className="flex flex-col">
                        <Ring color="rgb(112,48,140)" />
                        <span className="font-bold text-md text-swa-3">
                          please wait...
                        </span>
                      </div>
                    )}
                    {errorText !== "" && <TextError>{errorText}</TextError>}
                  </div>
                </div>
              </form>
            ))}
        </div> */}
    
      </Layout4>
    </>
  );
};

export default MspiForm2;
